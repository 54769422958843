export default {
  apiKey: 'AIzaSyByk64zJfj8bXfe1jt_rjf9x2QNzUHSJQ8',
  authDomain: 'placeshaker-app.firebaseapp.com',
  databaseURL: 'https://placeshaker-app.firebaseio.com',
  projectId: 'placeshaker-app',
  storageBucket: 'placeshaker-app.appspot.com',
  messagingSenderId: '129733429531',
  appId: '1:129733429531:web:56667e661cd8676d'
};

/* 
export default {
  apiKey: 'AIzaSyCkW_QlTxzDNBc19QUdwCA3qvS4qArXbC0',
  authDomain: 'placeshaker-pro.firebaseapp.com',
  databaseURL: 'https://placeshaker-pro.firebaseio.com',
  projectId: 'placeshaker-pro',
  storageBucket: 'placeshaker-pro.appspot.com',
  messagingSenderId: '594386417028'
}; 
*/

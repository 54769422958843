import {
  baseUrl,
  createClient,
  createQueryString,
  del,
  get,
  postForm
} from './util';

const client = createClient('experiences');

client.allByUser = params =>
  get(`${baseUrl}/experiences-by-user${createQueryString(params)}`);

client.createPhoto = (id, data) =>
  postForm(`${baseUrl}/experiences/${id}/photo`, data);

client.delPhoto = (eid, id) => del(`${baseUrl}/experiences/${eid}/photo/${id}`);

export default client;

import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import GalleryImage from './GalleryImage';
import GalleryModal from './GalleryModal';
import GalleryCanvas from '../GalleryCanvas';

class CustomGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      modal: false,
      selected: ''
    };
    this.buttonsStyle = {
      color: 'black',
      background: 'transparent',
      border: 'none',
      boxShadow: 'none'
    };
  }

  componentWillReceiveProps({ images, selectable, crudPoints }) {
    this.setState(ps => {
      return { ...ps, images, selectable, crudPoints };
    });
  }

  componentWillMount() {
    let { images, selectable, crudPoints } = this.props;
    this.setState(ps => {
      return { ...ps, images, selectable, crudPoints };
    });
  }

  imageClick(id) {
    this.setState(ps => {
      return { ...ps, selected: id, modal: true };
    });
  }

  closeModal() {
    this.setState(
      ps => {
        return { ...ps, selected: '', modal: false };
      }
      //() => this.props.updateMedias(this.state.images)
    );
  }

  closeOldModal() {
    this.setState(
      ps => {
        return { ...ps, selected: '', modal: false };
      },
      () => this.props.updateMedias(this.state.images)
    );
  }

  openNewTab() {
    let { images, selected } = this.state;
    let img = images.filter(i => i.id === selected);
    if (img.length) window.open(img[0].src, '_blank'); //to open new page
  }

  savePoints(data, selected) {
    let { images } = this.state;
    let index = images.findIndex(i => i.id === selected);
    images[index].elements = data;
    this.setState(
      ps => ({ ...ps, images }),
      () => this.props.updateImages(images)
    );
  }

  render() {
    let { images, modal, selected, selectable, crudPoints } = this.state;

    return (
      <Row id={'gallery-component'}>
        {images.map((i, index) => {
          return (
            <Col key={i.id} xs={6} sm={6} md={3} lg={2} className={'mb-4'}>
              <GalleryImage
                image={i}
                icon={
                  selectable ? (
                    <i className="fa fa-check-circle fa-2x text-info" />
                  ) : null
                }
                iconSelect={
                  selectable ? (
                    <i className="fa fa-check-circle-o fa-2x text-info" />
                  ) : null
                }
                selected={i.isSelected}
                clickHover={() => this.imageClick(i.id)}
                clickSelect={() => this.props.selectImage(i.id)}
              />
            </Col>
          );
        })}
        {modal && this.props.newGallery ? (
          <GalleryCanvas
            next={{
              icon: <i className="fas fa-caret-square-right fa-2x"></i>,
              styles: this.buttonsStyle
            }}
            prev={{
              icon: <i className="fas fa-caret-square-left fa-2x"></i>,
              styles: this.buttonsStyle
            }}
            close={{
              icon: <i className="fas fa-window-close fa-2x"></i>,
              styles: this.buttonsStyle
            }}
            resize={{
              icon: <i className="fas fa-compress-arrows-alt fa-2x"></i>,
              styles: this.buttonsStyle
            }}
            savePointButton={{
              icon: <i className="fas fa-save fa-2x"></i>,
              styles: {}
            }}
            deletePointButton={{
              icon: <i className="fas fa-trash-alt fa-2x"></i>,
              styles: {}
            }}
            closePointButton={{
              icon: <i className="fas fa-window-close fa-2x"></i>,
              styles: {}
            }}
            images={images}
            selected={selected}
            closeModal={() => this.closeModal()}
            crudPoints={crudPoints}
            savePoints={(data, selected) => this.savePoints(data, selected)}
          />
        ) : null}
        {modal && !this.props.newGallery ? (
          <GalleryModal
            images={images}
            selected={selected}
            openNewTab={() => this.openNewTab()}
            closeModal={() => this.closeOldModal()}
            crudPoints={crudPoints}
          />
        ) : null}
      </Row>
    );
  }
}

CustomGallery.defaultProps = {
  images: [],
  selectable: true,
  crudPoints: true
};

CustomGallery.propTypes = {
  images: PropTypes.array,
  selectable: PropTypes.bool,
  crudPoints: PropTypes.bool
};

export default CustomGallery;

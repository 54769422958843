import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { loadMaps } from '../../../helpers/actions/projects';

class Maps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      maps: []
    };
  }

  componentWillMount() {
    this.load();
  }

  load() {
    this.setState(ps => ({ ...ps, loading: true }));
    let { dispatch, projectId } = this.props;

    dispatch(loadMaps(projectId))
      .then(maps => {
        this.setState(ps => ({ ...ps, maps, loading: false }));
      })
      .catch(() => this.setState(ps => ({ ...ps, loading: false })));
  }

  render() {
    let { maps } = this.state;
    let { t } = this.props;

    return (
      <Row>
        <Col xs={12} style={{ textAlign: 'left' }}>
          <Trans>Models 3D</Trans>
        </Col>
        {maps.map((m, i) => {
          let iframe = null;
          if (m.system === 'MagicPlan') {
            iframe = (
              <iframe
                title={`${t('3D Magic Plan')} ${i}`}
                src={`https://cloud.magic-plan.com/plan/${m.code}/?embed=true`}
                width="100%"
                height="800px"
                allowFullScreen={true}
                style={{ border: 0 }}
                scrolling="no"
              />
            );
          }
          if (m.system === 'SketchUp') {
            iframe = (
              <iframe
                title={`${t('3D Map SketchUp')} ${i}`}
                src={'https://3dwarehouse.sketchup.com/embed/' + m.code}
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                width="100%"
                height="600px"
                allowFullScreen={true}
              />
            );
          }
          return (
            <Col key={i} xs={12} md={6}>
              <Card className={this.props.class}>
                <CardHeader>
                  {m.title && m.system ? (
                    m.title + ' - ' + m.system
                  ) : (
                    <Trans>3D Model</Trans>
                  )}
                </CardHeader>
                <hr />
                <CardBody>{iframe}</CardBody>
              </Card>
            </Col>
          );
        })}
      </Row>
    );
  }
}

export default connect()(translate('translations-fr')(Maps));

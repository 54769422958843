import { notify } from './index';

export const all = params => (dispatch, getState, { api }) =>
  api.Experiences.all(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const allByUser = params => (dispatch, getState, { api }) =>
  api.Experiences.allByUser(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const one = id => (dispatch, getState, { api }) =>
  api.Experiences.one(id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const save = experience => (dispatch, getState, { api }) =>
  api.Experiences.save(experience)
    .then(response => {
      dispatch(notify('info', 'Experience saved correctly!'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const remove = id => (dispatch, getState, { api }) =>
  api.Experiences.del(id)
    .then(response => {
      dispatch(notify('info', 'Experience deleted correctly!'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const addPhotos = (id, files) => (dispatch, getState, { api }) => {
  let fd = new FormData();
  for (let x = 0; x < files.length; x++) {
    fd.append('file' + x, files[x]);
  }

  return api.Experiences.createPhoto(id, fd)
    .then(response => {
      dispatch(notify('info', 'Photo saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
};

export const removePhotos = (eid, id) => (dispatch, getState, { api }) =>
  api.Experiences.delPhoto(eid, id)
    .then(response => {
      dispatch(notify('info', 'Photo removed'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

import { notify } from './index';

export const supervisorsContacts = params => (dispatch, getState, { api }) =>
  api.Pros.supervisorsContacts(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const clientsContacts = params => (dispatch, getState, { api }) =>
  api.Pros.clientsContacts(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const projects = params => (dispatch, getState, { api }) =>
  api.Pros.projects(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const visits = params => (dispatch, getState, { api }) =>
  api.Pros.visits(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const events = params => (dispatch, getState, { api }) =>
  api.Pros.events(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

// export const postulate = id => (dispatch, getState, { api }) =>
//   api.ProfessionalApp.postulate(id)
//     .then(response => {
//       dispatch(notify('info', 'Postulation saved'));
//       return response;
//     })
//     .catch(err => {
//       dispatch(notify('danger', err.message));
//       throw err;
//     });

// export const requests = params => (dispatch, getState, { api }) =>
//   api.ProfessionalApp.requests(params).catch(err => {
//     dispatch(notify('danger', err.message));
//     throw err;
//   });

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import { deleteNoteMedia } from '../../../helpers/actions/projects';
import { noteTypes } from '../../../helpers/nomenclators';
import { quillConfig } from '../../../helpers/formatters';
import { GalleryImage } from '../../../components';

class NoteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note: {},
      files: [],
      images: []
    };
  }

  componentWillMount() {
    let { note } = this.props;
    this.setState(ps => ({ ...ps, note }));
  }

  cleanModal() {
    this.setState({
      titleValid: null,
      textValid: null,
      commentsClientValid: null,
      note: {},
      files: [],
      images: []
    });
  }

  closeModal() {
    this.cleanModal();
    this.props.onCancel();
  }

  onChange(name, nameValid, value, valid) {
    let { note } = this.state;

    this.setState({
      note: { ...note, [name]: value },
      [nameValid]: valid ? 'has-success' : 'has-danger'
    });
  }

  validate() {
    let title = this.title;

    this.setState({
      [title.attributes.getNamedItem('namevalid').value]: title.validity.valid
        ? 'has-success'
        : 'has-danger'
    });

    return title.validity.valid;
  }

  onSaveNote() {
    let { note, files } = this.state;

    if (this.validate()) {
      this.cleanModal();
      this.props.onConfirm(note, files);
    }
  }

  handleImageChange(files) {
    let images = [];
    let promises = [];

    for (let i = 0; i < files.length; i++) {
      promises.push(
        new Promise(resolve => {
          let reader = new FileReader();
          reader.onloadend = () => {
            images.push(reader.result);
            resolve();
          };
          reader.readAsDataURL(files[i]);
        })
      );
    }

    Promise.all(promises)
      .then(() => {
        this.setState(ps => {
          return { ...ps, images, files };
        });
      })
      .catch(err => {});
  }

  handleClick() {
    let input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    input.onchange = e => {
      e.preventDefault();
      this.handleImageChange(e.target.files);
    };
    input.click();
  }

  deleteNoteImage(id) {
    let { projectId, dispatch } = this.props;
    let { note } = this.state;

    dispatch(deleteNoteMedia(projectId, note._id, id))
      .then(res => {
        let images = note.images.filter(i => i._id !== id);
        this.setState(ps => {
          return { ...ps, note: { ...ps.note, images } };
        });
      })
      .catch(err => {});
  }

  render() {
    let { note, images } = this.state,
      { t } = this.props;

    return (
      <Modal
        size={'lg'}
        isOpen={this.props.show}
        toggle={() => this.closeModal()}
      >
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.closeModal()}
        >
          {note && note._id ? t('Edit Note') : t('New Note')}
        </ModalHeader>
        <ModalBody>
          {note ? (
            <Row>
              <Col xs={12}>
                <FormGroup
                  className={
                    'has-label form-validation-40 ' + this.state.titleValid
                  }
                >
                  <Label>
                    <Trans>Title</Trans>
                  </Label>
                  <Input
                    type="text"
                    innerRef={node => (this.title = node)}
                    namevalid="titleValid"
                    value={note.title || ''}
                    required="required"
                    onChange={event =>
                      this.onChange(
                        'title',
                        'titleValid',
                        event.target.value,
                        event.target.validity.valid
                      )
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup
                  className={
                    'has-label form-validation-40 ' + this.state.textValid
                  }
                >
                  <Label>
                    <Trans>Description</Trans>
                  </Label>
                  <ReactQuill
                    innerRef={node => (this.text = node)}
                    namevalid="textValid"
                    value={note.text || ''}
                    modules={quillConfig}
                    className={'quill-text-editor'}
                    onChange={value =>
                      this.onChange('text', 'textValid', value, true)
                    }
                  />
                </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label>
                    <Trans>Type</Trans>
                  </Label>
                  <Select
                    className="primary"
                    innerRef={node => (this.type = node)}
                    namevalid="typeValid"
                    value={note.type}
                    options={noteTypes.map(p => ({ label: t(p), value: p }))}
                    onChange={event =>
                      this.onChange(
                        'type',
                        'typeValid',
                        event ? event.value : null,
                        !!event
                      )
                    }
                  />
                </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={note.completed}
                      onChange={event =>
                        this.setState({
                          note: {
                            ...note,
                            completed: event.target.checked
                          }
                        })
                      }
                    />
                    <span className="form-check-sign" />
                    <Trans>Completed</Trans>
                  </Label>
                </FormGroup>
              </Col>
              <Col sm={12} className={'mt-4'}>
                {note.images ? (
                  <Row>
                    {note.images.map(media => {
                      let i = {
                        id: media._id,
                        valide: media.valide,
                        label: media.label ? media.label : '',
                        name: media.name,
                        src: media.source,
                        points: media.points,
                        isSelected: false
                      };
                      return (
                        <Col
                          key={i.id}
                          xs={6}
                          sm={6}
                          md={4}
                          lg={4}
                          className={'mb-4'}
                        >
                          <GalleryImage
                            image={i}
                            icon={
                              <i className="fa fa-trash fa-2x text-danger" />
                            }
                            iconSelect={
                              <i className="fa fa-trash fa-2x text-danger" />
                            }
                            selected={i.isSelected}
                            clickHover={() => {}}
                            clickSelect={() => this.deleteNoteImage(i.id)}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                ) : null}
                <Row>
                  <Col sm={12}>
                    <hr />
                    <Button
                      size={'sm'}
                      color="info"
                      onClick={() => this.handleClick()}
                    >
                      <Trans>Select Images</Trans>
                    </Button>
                  </Col>
                  {images.map((i, index) => {
                    return (
                      <Col key={'note-image-' + index} sm={6} md={3}>
                        <div
                          className={'image-container'}
                          style={{ backgroundImage: 'url(' + i + ')' }}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            className="text-left"
            onClick={() => this.closeModal()}
          >
            <Trans>Close</Trans>
          </Button>
          <Button
            color="info"
            className="text-right"
            onClick={() => this.onSaveNote(note)}
          >
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(state => state)(translate('translations-fr')(NoteModal));

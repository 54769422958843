import { baseUrl, get, createQueryString } from './util';
import clients from './clients';
import pros from './pros';
import projects from './projects';
import materials from './materials';
import operations from './operations';
import visits from './Visits';
import providers from './providers';
import supervisors from './supervisors';
import pieces from './pieces';
import surfaces from './surfaces';
import packages from './packages';
import reports from './reports';
import leroyMerlin from './leroymerlin';
import documents from './documents';
import partners from './partners';
import experiences from './experiences';
import users from './users';

export const Clients = clients;
export const Projects = projects;
export const Materials = materials;
export const Operations = operations;
export const Pros = pros;
export const Visits = visits;
export const Providers = providers;
export const Supervisors = supervisors;
export const Pieces = pieces;
export const Surfaces = surfaces;
export const Packages = packages;
export const Reports = reports;
export const LeroyMerlin = leroyMerlin;
export const Documents = documents;
export const Partners = partners;
export const Experiences = experiences;
export const UserData = () => get(`${baseUrl}/user`);
export const Users = users;

export default {
  Clients,
  Projects,
  Materials,
  Operations,
  Pros,
  Visits,
  Providers,
  Supervisors,
  Pieces,
  Surfaces,
  Packages,
  Reports,
  LeroyMerlin,
  Documents,
  Partners,
  Experiences,
  Security: () => get(`${baseUrl}/login`),
  Board: {
    Stats: () => get(`${baseUrl}/pro/board`),
    Notifications: params =>
      get(`${baseUrl}/pro/notifications${createQueryString(params)}`)
  },
  Finances: {
    Stats: () => get(`${baseUrl}/pro/finances`)
  },
  UserData,
  Users
};

import React from 'react';
import { translate } from 'react-i18next';
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import authRoutes from '../../routes/auth';

class PagesHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.toggle = this.toggle.bind(this);
    this.activeRoute = this.activeRoute.bind(this);
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }
  render() {
    let { t } = this.props;

    return (
      <Navbar
        expand="lg"
        className={
          this.state.isOpen
            ? 'bg-white navbar-absolute'
            : 'navbar-transparent navbar-absolute'
        }
      >
        <Container>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <NavbarToggler onClick={this.toggle}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </NavbarToggler>
            </div>
            <Link to="/" className="navbar-brand font-weight-bold">
              Placeshaker
            </Link>
          </div>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {authRoutes.map((prop, key) => {
                if (prop.redirect) return null;
                return (
                  <NavItem key={key} className={this.activeRoute(prop.path)}>
                    <Link to={prop.path} className="nav-link font-weight-bold">
                      <i className={'now-ui-icons ' + prop.icon} />{' '}
                      {t(prop.short)}
                    </Link>
                  </NavItem>
                );
              })}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default translate('translations-fr')(PagesHeader);

import { baseUrl, getToken } from './util';
let client = {};

client.reportProjectsNotes = () =>
  getToken().then(token =>
    Promise.resolve({
      url: `${baseUrl}/reports/projects/notes`,
      httpHeaders: {
        Authorization: 'Bearer ' + token,
        'x-api-role': 'pro'
      },
      withCredentials: false
    })
  );

client.reportProjectsEvents = () =>
  getToken().then(token =>
    Promise.resolve({
      url: `${baseUrl}/reports/projects/events`,
      httpHeaders: {
        Authorization: 'Bearer ' + token,
        'x-api-role': 'pro'
      },
      withCredentials: false
    })
  );

client.reportDashboard = () =>
  getToken().then(token =>
    Promise.resolve({
      url: `${baseUrl}/admin/board/reports`,
      httpHeaders: {
        Authorization: 'Bearer ' + token,
        'x-api-role': 'pro'
      },
      withCredentials: false
    })
  );

client.reportFinances = () =>
  getToken().then(token =>
    Promise.resolve({
      url: `${baseUrl}/admin/finances/report`,
      httpHeaders: {
        Authorization: 'Bearer ' + token,
        'x-api-role': 'pro'
      },
      withCredentials: false
    })
  );

export default client;
